import React, { useState, useEffect } from "react";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  ImageRun,
  Footer,
} from "docx";
import { saveAs } from "file-saver";
import EPDTable from "../sidemenu-pages/sidemenu-components/EPDTable";
import EPDPlot from "./sidemenu-components/EPDPlot";
import TableComponent from "../environment-page-components/Table";
import ProcessTable from "../environment-page-components/ProcessTable";
import EnvFactorTable from "../environment-page-components/EnvFactorTable";
import Footprint from "../environment-page-components/Footprint";
import SystemBoundary from "../environment-page-components/SystemBoundary";
import LifeCycleAssessment from "../environment-page-components/LifeCycleAssessment";
import EPDLifeCycleAssessment from "./sidemenu-components/EPDLifeCycleAssessment";
import "../style/sidemenu-pages-style/StandardSuppliersLibrary.css";
import Header from "../app_components/Header";
import { translations } from "../app_components/Translation";
import { FiDownload } from "react-icons/fi";

function StandardSuppliersLibrary({ selectedLanguage }) {
  const pageType = "supplier";
  const [selectedRowData, setSelectedRowData] = useState([]); //selected rows of main table
  const [selectedProcessData, setSelectedProcessData] = useState([]); //selected rows of process table
  const [selectedEnvFactorData, setSelectedEnvFactorData] = useState([]); //selected rows of env factor table
  const [processData, setProcessData] = useState([]); //All rows of process table
  const [mainTableGridApi, setMainTableGridApi] = useState(null); //gridAPI of the main table
  const [processTableGridApi, setProcessTableGridApi] = useState(null); //gridAPI of the process table
  const [envFactorTableGridApi, setEnvFactorTableGridApi] = useState(null); //gridAPI of the env factor table
  const [trackView, setTrackView] = useState(false); // State to track if track view is open
  const [allEnvFactorRows, setAllEnvFactorRows] = useState([]); // All rows of env factor table
  const [allTableRows, setAllTableRows] = useState([]); // All rows of main table
  const [allProcessRows, setAllProcessRows] = useState([]); // All rows of process table

  const [footprintChart, setFootprintChart] = useState(null); // Footprint image
  const [footprintDoughnut, setFootprintDoughnut] = useState(null); // Footprint doughnut chart

  const [systemBoundaryTable, setSystemBoundaryTable] = useState(null); // Data for system boundary

  const [lcaData, setLcaData] = useState([]); // Data for life cycle assessment

  const [gwpValues, setGwpValues] = useState([]);
  const [isBoxplotView, setIsBoxplotView] = useState(false);

  const [firstSelectedRowData, setFirstSelectedRowData] = useState([]);
  console.log("firstSelectedRowData:", firstSelectedRowData);

  const selectedText = translations[selectedLanguage];

  console.log("lcadata:", lcaData);

  console.log("systemBoundaryTable:", systemBoundaryTable);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString(
    selectedLanguage === "FR" ? "fr-FR" : "en-US",
    { year: "numeric", month: "long", day: "numeric" }
  );
  const isStandardSuppliersLibrary = true;


  useEffect(() => {
    if (selectedRowData.length === 0) {
      setProcessData([]);
      setSelectedProcessData([]);
      setAllProcessRows([]);
      setSelectedEnvFactorData([]);
      setAllEnvFactorRows([]);
      setProcessTableGridApi(null);
    }
  }, [selectedRowData]);

  function calculateStandardDeviation(values) {
    if (values.length === 0) {
      return 0; // Return 0 or another default value when the array is empty
    }

    const mean = values.reduce((a, b) => a + b, 0) / values.length;
    return Math.sqrt(
      values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / 
        values.length
    );
  }

  return (
    <div className="supplier-utilizer">
      <div className="table-div">
        <Header selectedLanguage={selectedLanguage} />
        <div className="EDP-table-container">
          {/* Pass the callback to EPDTable to update the parent's state */}
          <EPDTable
            setGwpValues={setGwpValues}
            setIsBoxplotView={setIsBoxplotView}
            calculateStandardDeviation={calculateStandardDeviation}
            setFirstSelectedRow={setFirstSelectedRowData}
          ></EPDTable>

          {/* Conditionally render the EPDPlot based on isBoxplotView */}
          {isBoxplotView && <EPDPlot gwpValues={gwpValues} calculateStandardDeviation={calculateStandardDeviation}/>}
        </div>
        <div className="life-cycle-assessment-div-sst">
          <EPDLifeCycleAssessment selectedLanguage={selectedLanguage} firstSelectedRowData={firstSelectedRowData}/>
        </div>
      </div>
    </div>
  );
}

export default StandardSuppliersLibrary;
