import React, { useState, useEffect } from "react";
import "../style/components-style/LifeCycleAssessment.css";
import { Icon } from "@iconify/react";
import config from "../config";
import { translations } from "../app_components/Translation";
import { useAuth } from "../authentication/AuthProvider";

function LifeCycleAssessment({
  selectedRow,
  selectedLanguage,
  setLcaData,
  archive,
  isArivuLibrary,
  isAdmin,
  isStandardSuppliersLibrary,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [processData, setProcessData] = useState([]);
  const [envFactors, setEnvFactors] = useState([]);
  const [gwpInfo, setGwpInfo] = useState([]);
  const [editableLcaInfo, setEditableLcaInfo] = useState({});
  const [lcaData, setLocalLcaData] = useState({
    generalInformation: {
      ReferencePCR: "",
      DeclaredUnit: "",
      Scope: "",
      LCA_Software: "",
      LCI_Database: "",
      LCIA_Methodology: "",
    },
    cutOff: "",
    allocationProcedure: "",
    primarySources: "",
    interpretation: "",
    formattedReferences: "",
  });
  const selectedText =
    translations[selectedLanguage]?.lifeCycleAssessment || {};
  const { user } = useAuth();
  const userId = user ? user.username : null;
  const [isHovered, setIsHovered] = useState(false);
  const readOnly = !isAdmin && isArivuLibrary;

  useEffect(() => {
    if (!selectedRow) {
      console.log("Resetting fields because selectedRow is undefined");
      setEditableLcaInfo({
        referencePCR: "",
        declaredUnit: "",
        scope: "",
        lcaSoftware: "",
        lciDatabase: "",
        lciaMethodology: "",
        cutOff: "",
        allocationProcedure: "",
        primarySources: "",
        interpretation: "",
      });
      setLocalLcaData({
        generalInformation: {
          ReferencePCR: "",
          DeclaredUnit: "",
          Scope: "",
          LCA_Software: "",
          LCI_Database: "",
          LCIA_Methodology: "",
        },
        cutOff: "",
        allocationProcedure: "",
        primarySources: "",
        interpretation: "",
        formattedReferences: "",
      });

      console.log("editableLcaInfo:", editableLcaInfo);
      console.log("lcaData:", lcaData);
    }
  }, [selectedRow]);

  const toggleContainer = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (field, value) => {
    if (readOnly) return; // Prevent changes if read-only
    setEditableLcaInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const generalInfo = (label, value, field, smallTextArea = false) => (
    <div className="info-row">
      <div className="label-lca">{label}</div>
      <textarea
        className={`general-info-textarea ${
          smallTextArea ? "small-textarea" : ""
        } ${readOnly ? "readonly-textarea" : ""}`}
        value={editableLcaInfo[field] || value}
        onChange={(e) => handleChange(field, e.target.value)}
        readOnly={readOnly || archive} // Combine archive and readOnly logic
      />
    </div>
  );

  const textArea = (content, field) => (
    <div>
      <textarea
        className={`editable-textarea ${
          readOnly || archive ? "readonly-textarea" : ""
        }`}
        value={editableLcaInfo[field] || content}
        onChange={(e) => handleChange(field, e.target.value)}
        readOnly={readOnly || archive} // Combine archive and readOnly logic
      />
    </div>
  );

  useEffect(() => {
    console.log("Fetching initial data for processes and env factors");

    const fetchData = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/fetch_data`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            table_name: "processes",
            condition: `product_id = '${selectedRow.id}' OR design_id = '${selectedRow.id}'`,
          }),
        });
        const processes = await response.json();
        setProcessData(processes);
        console.log("Fetched process data:", processes);

        const envFactorsPromises = processes.map((process) =>
          fetch(
            `${config.apiUrl}/api/get-env-factor-by-process-id?process_id=${process.id}`
          ).then((res) => res.json())
        );
        const envFactorsResults = await Promise.all(envFactorsPromises);
        const envFactorsData = envFactorsResults.flat();
        setEnvFactors(envFactorsData);
        console.log("Fetched environmental factors data:", envFactorsData);

        const gwpResponse = await fetch(`${config.apiUrl}/fetch_data`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ table_name: "emission_info" }),
        });
        const gwpInfoData = await gwpResponse.json();
        setGwpInfo(gwpInfoData);
        console.log("Fetched GWP info:", gwpInfoData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedRow]);

  const fetchLcaInfo = async (templateId) => {
    try {
      const response = await fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table_name: "LCA_info",
          condition: `template_id = '${templateId}'`,
        }),
      });
      const lcaInfoData = await response.json();
      console.log("Fetched LCA info:", lcaInfoData);

      // If no data is found, insert a new row
      if (!lcaInfoData || lcaInfoData.length === 0) {
        console.log("No data found for template_id. Adding a new row...");
        await addLcaInfo(templateId);
      }

      return lcaInfoData;
    } catch (error) {
      console.error("Error fetching LCA info:", error);
      return null;
    }
  };

  const addLcaInfo = async (templateId) => {
    try {
      const apiUrl = `${config.apiUrl}/add_data`;
      const body = JSON.stringify({
        table_name: "LCA_info",
        data: {
          user_id: userId,
          template_id: templateId,
          reference_pcr: "",
          declared_unit: "",
          scope: "",
          lca_software: "",
          lci_database: "",
          lcia_methodology: "",
          cutoff: "",
          allocation_procedure: "",
          primary_sources: "",
          interpretation: "",
        },
      });

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const result = await response.json();
      if (result.status === "success") {
        console.log(
          "New LCA info added successfully for template_id:",
          templateId
        );
      } else {
        console.error("Failed to add new LCA info:", result.message);
      }
    } catch (error) {
      console.error("Error adding new LCA info:", error);
    }
  };

  const loadLcaInfo = async () => {
    if (!selectedRow || !selectedRow.id) return;

    const lcaInfoData = await fetchLcaInfo(selectedRow.id);

    if (lcaInfoData && lcaInfoData.length > 0) {
      const data = lcaInfoData[0];
      setEditableLcaInfo({
        referencePCR: data.reference_pcr || " ",
        declaredUnit: data.declared_unit || " ",
        scope: data.scope || " ",
        lcaSoftware: data.lca_software || " ",
        lciDatabase: data.lci_database || " ",
        lciaMethodology: data.lcia_methodology || " ",
        cutOff: data.cutoff || " ",
        allocationProcedure: data.allocation_procedure || " ",
        primarySources: data.primary_sources || " ",
        interpretation: data.interpretation || " ",
      });

      setLocalLcaData((prevData) => ({
        ...prevData,
        generalInformation: {
          ReferencePCR: data.reference_pcr || " ",
          DeclaredUnit: data.declared_unit || " ",
          Scope: data.scope || " ",
          LCA_Software: data.lca_software || " ",
          LCI_Database: data.lci_database || " ",
          LCIA_Methodology: data.lcia_methodology || " ",
        },
        cutOff: data.cutoff || " ",
        allocationProcedure: data.allocation_procedure || " ",
        primarySources: data.primary_sources || " ",
        interpretation: data.interpretation || " ",
      }));
      setLcaData((prevData) => ({
        ...prevData,
        generalInformation: {
          ReferencePCR: data.reference_pcr || " ",
          DeclaredUnit: data.declared_unit || " ",
          Scope: data.scope || " ",
          LCA_Software: data.lca_software || " ",
          LCI_Database: data.lci_database || " ",
          LCIA_Methodology: data.lcia_methodology || " ",
        },
        cutOff: data.cutoff || " ",
        allocationProcedure: data.allocation_procedure || " ",
        primarySources: data.primary_sources || " ",
        interpretation: data.interpretation || " ",
      }));
    }
  };

  useEffect(() => {
    loadLcaInfo();
  }, [selectedRow]);

  useEffect(() => {
    console.log("envFactors:", envFactors);
    console.log("processData:", processData);
    console.log("gwpInfo:", gwpInfo);

    if (envFactors.length && processData.length && gwpInfo.length) {
      const formattedEnvFactors = envFactors
        .map((factor) => {
          const process =
            processData.find((p) => p.id === factor.process_id) || {};
          const reference = findReference(
            factor.type,
            factor.category,
            factor.description
          );
          return `• LCA Stage: ${process.lca_stage || "N/A"}, Process Name: ${
            process.name || "N/A"
          }, Type: ${factor.type}, Category: ${factor.category}, Description: ${
            factor.description
          }, Reference: ${reference}`;
        })
        .filter(Boolean)
        .join("\n");

      setLcaData((prevData) => ({
        ...prevData,
        formattedReferences: formattedEnvFactors,
      }));

      setEditableLcaInfo((prevInfo) => ({
        ...prevInfo,
        formattedReferences: formattedEnvFactors,
      }));
    }
  }, [envFactors, processData, gwpInfo]);

  const findReference = (type, category, description) => {
    const formattedType = type?.trim().toLowerCase() || "";
    const formattedCategory = category?.trim().toLowerCase() || "";
    const formattedDescription = description?.trim().toLowerCase() || "";

    const match = gwpInfo.find((info) => {
      return (
        (info.type?.trim().toLowerCase() || "") === formattedType &&
        (info.category?.trim().toLowerCase() || "") === formattedCategory &&
        (info.description?.trim().toLowerCase() || "") === formattedDescription
      );
    });

    return match ? match.reference : "No reference found";
  };

  const formattedPrimarySources = editableLcaInfo.primarySources || "";

  const escapeSingleQuotes = (str) => {
    return str.replace(/'/g, "\\'");
  };

  const updateLcaInfo = async () => {
    if (userId) {
      try {
        const apiUrl = `${config.apiUrl}/update_data`;
        const body = JSON.stringify({
          table_name: "LCA_info",
          data: {
            reference_pcr: escapeSingleQuotes(editableLcaInfo.referencePCR),
            declared_unit: escapeSingleQuotes(editableLcaInfo.declaredUnit),
            scope: escapeSingleQuotes(editableLcaInfo.scope),
            lca_software: escapeSingleQuotes(editableLcaInfo.lcaSoftware),
            lci_database: escapeSingleQuotes(editableLcaInfo.lciDatabase),
            lcia_methodology: escapeSingleQuotes(
              editableLcaInfo.lciaMethodology
            ),
            cutoff: escapeSingleQuotes(editableLcaInfo.cutOff),
            allocation_procedure: escapeSingleQuotes(
              editableLcaInfo.allocationProcedure
            ),
            primary_sources: escapeSingleQuotes(editableLcaInfo.primarySources),
            interpretation: escapeSingleQuotes(editableLcaInfo.interpretation),
            template_id: selectedRow.id,
          },
          condition: `template_id = '${selectedRow.id}'`,
        });

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });

        const result = await response.json();

        if (result.status === "success") {
          alert("Information updated successfully!");
          fetchLcaInfo();
          loadLcaInfo();
        } else {
          alert("Failed to save information.");
        }
      } catch (error) {
        console.error("Error updating LCA info:", error);
        alert("Error saving information.");
      }
    } else {
      alert("User ID is null. Cannot update information.");
    }
  };

  return (
    <div
      className="lca-container"
      style={{
        backgroundColor:
          archive || isArivuLibrary || isStandardSuppliersLibrary
            ? "#faf4e8"
            : "white",
      }}
    >
      <div className="container-header">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            color: isHovered ? "grey" : "black",
          }}
        >
          {selectedText.title || "Life Cycle Assessment"}
        </h2>
      </div>
      {isOpen && (
        <>
          <div className="section general-info-layout">
            <div className="general-info-header">
              <h2 className="centered-title">
                {selectedText.generalInformation || "General Information"}
              </h2>
              {!archive && !readOnly && (
                <button
                  className="right-button"
                  onClick={updateLcaInfo}
                  title="Save LCA"
                >
                  <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
                </button>
              )}
            </div>
            <div className="general-info-content">
              {generalInfo(
                selectedText.referencePCR || "Reference PCR",
                editableLcaInfo.referencePCR,
                "referencePCR"
              )}
              {generalInfo(
                selectedText.declaredUnit || "Declared Unit",
                editableLcaInfo.declaredUnit,
                "declaredUnit",
                true
              )}
              {generalInfo(
                selectedText.scope || "Scope",
                editableLcaInfo.scope,
                "scope",
                true
              )}
              {generalInfo(
                selectedText.lcaSoftware || "LCA Software",
                editableLcaInfo.lcaSoftware,
                "lcaSoftware",
                true
              )}
              {generalInfo(
                selectedText.lciDatabase || "LCI Database(s)",
                editableLcaInfo.lciDatabase,
                "lciDatabase",
                true
              )}
              {generalInfo(
                selectedText.lciaMethodology || "LCIA Methodology",
                editableLcaInfo.lciaMethodology,
                "lciaMethodology"
              )}
            </div>
          </div>
          <div className="section">
            <h2>{selectedText.cutOff || "Cut-Off"}</h2>
            {textArea(editableLcaInfo.cutOff, "cutOff")}
          </div>
          <div className="section">
            <h2>
              {selectedText.allocationProcedure || "Allocation Procedure"}
            </h2>
            {textArea(
              editableLcaInfo.allocationProcedure,
              "allocationProcedure"
            )}
          </div>
          <div className="section">
            <h2>{selectedText.primarySources || "Primary Sources"}</h2>
            {textArea(formattedPrimarySources, "primarySources")}
          </div>
          <div className="section">
            <h2>{selectedText.interpretation || "Interpretation"}</h2>
            {textArea(editableLcaInfo.interpretation, "interpretation")}
          </div>
          <div className="section">
            <h2>
              {selectedText.references || "References / Environmental Factors"}
            </h2>
            <textarea
              value={editableLcaInfo.formattedReferences || ""}
              readOnly
              className="readonly-textarea" // Apply custom styling here if needed
            />
          </div>
        </>
      )}
    </div>
  );
}

export default LifeCycleAssessment;
