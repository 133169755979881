import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/sidemenu-pages-style/SideMenu.css';
import Header from '../app_components/Header';
import { MdContactSupport, MdLibraryBooks } from 'react-icons/md';
import { FaCog } from 'react-icons/fa';
import { Icon } from '@iconify/react';
import { translations } from '../app_components/Translation'; // Import translations
import { useAuth } from "../authentication/AuthProvider"; // Import the auth hook

function SideMenu({ isOpen, selectedLanguage }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLibrariesOpen, setIsLibrariesOpen] = useState(false);

    const logOut = () => {
        auth.logout();
        navigate('/');
    }

    if (!isOpen) return null;

    const selectedText = translations[selectedLanguage]?.sideMenu || {};

    return (
        <div className="side-menu-overlay">
            <Header selectedLanguage={selectedLanguage} />
            <div className="side-menu">
                <ul>
                    <li>
                        <Link to="/support" className="side-menu-link">
                            <MdContactSupport className="side-menu-icon" /> {selectedText.support || 'Support'}
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings" className="side-menu-link">
                            <FaCog className="side-menu-icon" /> {selectedText.settings || 'Settings'}
                        </Link>
                    </li>
                    {/* <li>
                        <div className="side-menu-link" onClick={() => setIsLibrariesOpen(!isLibrariesOpen)}>
                            <MdLibraryBooks className="side-menu-icon" /> {selectedText.libraries || 'Libraries'}
                        </div>
                        {isLibrariesOpen && (
                            <ul className="nested-menu">
                                <li>
                                    <Link to="/userlibrary" className="side-menu-link">
                                        {selectedText.userLibrary || 'User Library'}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/standardsupplierslibrary" className="side-menu-link">
                                        {selectedText.standardSuppliersLibrary || 'Standard Suppliers Library'}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/arivulibrary" className="side-menu-link">
                                        {selectedText.arivuLibrary || 'Arivu Library'}
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li> */}
                </ul>
                <button className="logout-button" onClick={logOut}>
                    {selectedText.logout || 'Logout'}
                </button>
            </div>
        </div>
    );
}

export default SideMenu;
